//@author: devin
import { Injector } from "@angular/core";

export class ServiceLoader {
    private static instanceCache: any = [];

    //set in app.module constructor
    public static injector: Injector = undefined;

    public static get(serviceClass: any) {
        let idx: any = ServiceLoader.instanceCache.indexOf(serviceClass);
        if (idx > -1) {
            return ServiceLoader.instanceCache[idx];
        }
        return ServiceLoader.injector.get(serviceClass);
    }
}
