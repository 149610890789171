//@author: devin
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache, Invalidate } from "../decorators/cache.decorator";

export class SettingsService {
    @Cache({ targetName: "SettingsService", immutable: true })
    public static async getSettings() {
        const resp = await DataAccess.get<{ attrs: { attr: Settings[] } }>("/public/settings/series25.json");
        let ret: {
            [key in PublicSettings]?: string;
        } = {};
        jSith.forEach(resp?.attrs?.attr, (key, value: Settings) => {
            ret[value.attr_name] = value.attr_value;
        });
        return ret;
    }

    @Invalidate({ serviceName: "SettingsService", methodName: "getSettings" })
    public static setSetting(setting: PublicSettings, value: string | number) {
        return DataAccess.put("/public/settings/series25.json?attr_name=" + setting + "&attribute_value=" + value);
    }
}

export type Settings = {
    attr_name: PublicSettings;
    attr_value: string;
};

export type PublicSettings =
    | "CAN_USE_EVAL25"
    | "CAN_USE_OPTIMIZER"
    | "CAN_USE_S25"
    | "CAN_USE_X25"
    | "CONFIG_SECURITY_GROUPS"
    | "HAS_LEGACY_SIS"
    | "M25_ENABLE"
    | "SYNC_BOUND_VCAL_EVENTS"
    | "USE_NEW_API";
