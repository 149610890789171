import { DataAccess } from "../dataaccess/data.access";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { Proto } from "../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;
import { Timeout } from "../decorators/timeout.decorator";

export interface StandardSchedule {
    id?: number;
    start_time?: number;
    end_time?: number;
    weekskip?: NumericalBoolean;
    dow?: string;
    pre_range?: NumericalBoolean;
    post_range?: NumericalBoolean;
    schedule_id?: number;
    schedule_name?: string;
}

export interface StandardScheduleList {
    id: number;
    name: string;
}

export class StandardScheduleService {
    @Timeout
    @Cache({ immutable: true, targetName: "StandardScheduleService" })
    public static getStandardScheduleList(typeId: number): Promise<StandardScheduleList[]> {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/standard_schedules/list.json?typeId=" + typeId,
                "StandardScheduleService.getStandardSchedulesList",
            ),
        ).then(function (data) {
            return data?.root?.item || [];
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "StandardScheduleService" })
    public static getStandardSchedule(itemIds: number[]) {
        itemIds = S25Util.array.forceArray(itemIds);
        const payload = { mapxml: { itemId: itemIds.join(",") } };
        return DataAccess.post<{ root: StandardSchedule[] }>(
            DataAccess.injectCaller(
                "/standard_schedules/details.json?request_method=get",
                "StandardScheduleService.getStandardSchedule",
            ),
            payload,
        ).then(function (data) {
            return data?.root || [];
        });
    }

    public static formatPattern(schedule: StandardSchedule): string {
        const dayCodeMap: { [key: string]: string } = {
            U: "SU",
            M: "MO",
            T: "TU",
            W: "WE",
            R: "TH",
            F: "FR",
            S: "SA",
        };
        const endTime = S25Util.date.toTimeStr(S25Util.timeConvert(schedule.end_time), true);
        const startTime = S25Util.date.toTimeStr(S25Util.timeConvert(schedule.start_time), true);

        return `${startTime.trim()}|${endTime.trim()}|W${schedule.weekskip + 1} ${schedule.dow
            .split("")
            .map((day) => dayCodeMap[day])
            .join(" ")}`;
    }
}
