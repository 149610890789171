//@author: devin

import { DataAccess } from "../dataaccess/data.access";

export class SortOrderService {
    public static setSortOrder(type: string, itemTypeId: string | number, sortOrderPayload: any) {
        let url = "/object/sort.json?type_name=" + type;
        if (itemTypeId) {
            url += "&itemTypeId=" + itemTypeId;
        }
        return DataAccess.put(url, {
            root: {
                sortOrder: sortOrderPayload || [],
            },
        });
    }
}
