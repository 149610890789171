//@author: travis
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";

export class SequenceService {
    public static getSequenceId(sequenceName: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/sequence/id.json?itemName=" + sequenceName + "&rand=" + S25Util.generateQuickGUID(),
                "SequenceService.getSequenceId",
            ),
        ).then(
            function (data) {
                var sequenceId = S25Util.propertyGet(data, "sequence_id");
                return sequenceId && parseInt(sequenceId);
            },
            function (error) {
                return null;
            },
        );
    }
}
