//@author: devin
declare global {
    interface Window {
        angBridge: any;
    }
}

//todo: this is just a proxy class to angularJS StateService; once we migrate states and routing into Angular proper, then
//todo: we need to replace this proxy class with calls to the Angular router and Angular states
export class StateService {
    public static gotoItem(item: any, newTab?: boolean, forceReload?: boolean) {
        return window.angBridge.$injector.get("StateService").gotoItem(item, newTab, forceReload);
    }

    public static putRecentlyViewedItem(itemTypeId: number, itemId: number, itemName: string) {
        return window.angBridge.$injector.get("StateService").putRecentlyViewedItem(itemTypeId, itemId, itemName);
    }

    public static putRecentlyViewedItemFetchName(itemTypeId: number, itemId: number, itemName?: string) {
        return window.angBridge.$injector
            .get("StateService")
            .putRecentlyViewedItemFetchName(itemTypeId, itemId, itemName);
    }

    public static createEvent(item: any, itemTypeId: number) {
        return window.angBridge.$injector.get("StateService").createEvent(item, itemTypeId);
    }

    public static gotoRose(item: any) {
        return window.angBridge.$injector.get("StateService").gotoRose(item);
    }

    public static go(stateName: string, params: any, options: any, newTab?: any) {
        return window.angBridge.$injector.get("StateService").go(stateName, params, options, newTab);
    }

    public static ItemTypeNorm: any = {
        11: 1, //event
        12: 2, //organization
        14: 4, //location
        1104: 4,
        1204: 4,
        407: 4,
        16: 6, //resource
        1106: 6,
        1206: 6,
        607: 6,
        201: 3001, //event group: sugar for simple event search
        204: 3004, //location group: sugar for simple location search
        206: 3006, //resource group: sugar for simple resource search
        10101: 10, //task
        10102: 10,
        10103: 10,
        10104: 10,
        10001: 101, //event search
        10002: 101,
        10003: 101,
        10004: 101,
        10051: 101,
        10052: 101,
    };
}
